<template>
    <div class="written-question column">
        <div class="row">
            <AudioToggle v-if="question.audio" :audio="question.audio"/>
            <div class="row">
                <span>{{question.order}}.</span>
                <span v-html="question.question"> </span>
            </div>
        </div>
        <div class="row">
            <textarea v-model="studentAnswer" class="custom-scrollbar"
            :readonly="isAssessmentSubmitted" :placeholder="`${$t('typeInAnAnswer')}...`"/>
        </div>
    </div>
</template>

<script>
import AudioToggle from "@/common/AudioToggle.vue"
import QuestionMixin from './QuestionMixin.js'
export default {
    name: "WrittenQuestion",
    mixins:[QuestionMixin],
    components: {
        AudioToggle
    },
    methods:{
        updateStudentAnswer(){
            if(!this.studentAnswer)
                return
            this.$store.dispatch('updateWrittenStudentAnswer', {
                assessmentId: this.assessment.id,
                questionId: this.question.id,
                writtenText: this.studentAnswer
            })
        }
    }
}
</script>

<style lang="less" scoped>
.written-question{
    margin: 0 auto;
    width: 944px;
    background-color: #EAEBFF;

    .row:first-child{
        min-height: 40px;
        margin-top: 26px;
        .audio-toggle{
            margin-left: 32px;
        }  
        .row{
            margin-top: 0;
            span{
                margin: 0;
                margin-left: 32px;
                min-width: 24px;
                max-width: 800px;
                color: #0A1554;
                font-family: Roboto;
                font-size: 16px;
                letter-spacing: 0.5px;
                line-height: 28px;
                &:nth-child(2){
                    margin-left: 8px;
                    margin-top: -1em;
                }
            }
        }  
    }

    .row:nth-child(2){
        margin-bottom: 20px;
    }
    textarea{
        all: unset;
        min-height: 144px;
        max-width: 840px;
        min-width: 840px;
        width: 840px;
        background-color: #FFFFFF;
        margin-left: 32px;
        color: #0A1554;
        font-family: Roboto;
        font-size: 16px;
        letter-spacing: 0.5px;
        line-height: 18px;
        resize: none;
        border: none;
        padding: 24px;

        overflow-wrap: break-word;
        white-space:pre-wrap;
    }
}
</style>
