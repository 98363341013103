<template>
    <div class="question" v-if="question">
        <div class="row">
            <AudioToggle v-if="question.audio" :audio="question.audio"/>
            <span>{{question.order}}.</span>
            <span id="question" v-html="question.question"> </span>
        </div>
        <div class="choices">
            <label class="container" v-for="(choice, index) in question.choices"
             :key="index">{{choice.text}}
                <input :name="question.id" type="radio" :disabled="isAssessmentSubmitted"
                     :value="choice.id" v-model="studentAnswer"/>
                <span class="checkmark" :class="checkMarkStatus"></span>
            </label>    
        </div>
    </div>
</template>
<script>
import AudioToggle from '@/common/AudioToggle.vue'

export default {
    name: 'MultipleChoiceQuestion', 
    components:{
        AudioToggle
    },
    props:{
        question: Object, 
    }, 
    computed:{
        studentAnswer:{
            set(answer){
                if(this.isPreviewMode)
                    return
                if(this.isAssessmentSubmitted){
                    this.$store.commit('addSnackBarNotification',{
                        message: this.$t('submittedAssessmentAlert')
                    })
                    return
                }
                this.$store.dispatch('setMultipleChoiceQuestionStudentAnswer', {
                        questionId: this.question.id,
                        assessmentId: this.assessment.id,
                        studentAnswerChoiceId: answer
                    })
                    .then(()=>{
                        this.$store.commit('updateMultipleChoiceQuestionStudentAnswer',{
                            question: this.question, 
                            answer: answer
                        })
                    })
            }, 
            get(){
                let correctAnwer =  this.question.choices.find(e => e.correct)
                return correctAnwer ? correctAnwer.id : null
            }
        }, 
        isPreviewMode(){
            return this.$store.state.isPreviewModeActive
        }, 
        assessment(){
            return this.$store.state.assessment
        }, 
        isAssessmentSubmitted(){
            return this.assessment.assessmentCompleted
        }, 
        checkMarkStatus(){
            if(this.question.isCorrect === null)
                return
            return this.studentAnswer != this.question.correctChoiceId
                ? 'failed' : 'correct'
        }
    }
}
</script>
<style lang="less" scoped>

.question{
    height: auto;
    min-width: 592px;
    margin-top: 16px;
    
    .row{
        align-content: center;
        img{
            align-self: center;
        }
        .audio-toggle{
            margin-right: 24px;
        }
        span{
           min-width: 24px;
           min-height: 28px;
           color: #0A1554;
           font-family: 'Roboto', sans-serif;
           font-size: 20px;
           letter-spacing: 0.5px;
           line-height: 28px;
           white-space: normal;
       }
        #question{
            margin-top: -1em;
            margin-left: 8px;
        }
    }
    .choices{
        margin-left: 138px;
        margin-top: 12px;
        .container{
            display: block;
            margin-top: 14px;
        }
    }
    
}

.container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;


    min-height: 20px;
    color: #0A1554;
    font-family: 'Roboto', sans-serif;
    font-size: 18px;
    font-weight: 300;
    letter-spacing: 0.25px;
    line-height: 22px;
}

/* Hide the browser's default radio button */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  max-width: 200px;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 14px;
  width: 14px;
  background-color: #D8D8D8;
  border-radius: 50%;
  margin-top: 3px;
}

.container:hover input ~ .checkmark {
  background-color: #ccc;
}

.container input:checked ~ .checkmark {
  background-color: #0A1554;
}
.container input:checked ~ .checkmark.failed {
  background-color: #ff5252;
}
.container input:checked ~ .checkmark.correct {
  background-color: #B8E986;
}

</style>

