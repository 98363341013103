import { render, staticRenderFns } from "./WrittenSection.vue?vue&type=template&id=54950ec6&scoped=true&"
import script from "./WrittenSection.vue?vue&type=script&lang=js&"
export * from "./WrittenSection.vue?vue&type=script&lang=js&"
import style0 from "./WrittenSection.vue?vue&type=style&index=0&id=54950ec6&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "54950ec6",
  null
  
)

component.options.__file = "WrittenSection.vue"
export default component.exports