<template>
    <div class="essay-answer row">
        <section class="column">
            <AudioToggle :audio="question.audio || {}"/>
        </section>
        <section>
            <div class="row">
                <span>{{question.order}}.</span>
                <span v-html="question.question"> </span>
            </div>
            <textarea   name="studentAnswer" v-model="studentAnswer" class="custom-scrollbar"
            @focus="ToggleAutoSaveInterval()"  @blur="clearAutoSaveInterval()"
             :readonly="isAssessmentSubmitted"></textarea>
        </section>
    </div>
</template>
<script>
import AudioToggle from '@/common/AudioToggle.vue'
import QuestionMixin from './QuestionMixin.js'
export default {
    name:'EssayTextArea',
    mixins:[QuestionMixin],
    components:{
        AudioToggle,
    },
    methods:{
        updateStudentAnswer(){
            if(!this.studentAnswer)
                return
            this.$store.dispatch('updateEssayStudentAnswer', {
                assessmentId: this.assessment.id,
                questionId: this.question.id,
                essayText: this.studentAnswer
            })
        }
    }
}
</script>
<style lang="less" scoped>
.audio-toggle{
    margin-top: 27px;
    margin-left: 32px;
}
.essay-answer.row{
    margin: 0 auto;
	min-height: 328px;
	width: 944px;
    background-color: #EAEBFF;
    font-family: 'Roboto', sans-serif;
    color: rgba(0,0,0,0.38);

    section:nth-child(2){
        margin-left: 60px;
        margin-right: 24px;
        width: 800px;
        height: 100%;
        padding: 16px;
        span{
            display: block; 
            min-height: 81px;
            min-width: 24px;
            color: #0A1554;
            font-size: 20px;
            letter-spacing: 0.5px;
            line-height: 28px;
            white-space: normal;
            width: auto;
            margin-left: -32px;
            &:nth-child(2){
                margin-left: 8px;
                margin-top: -1em;
            }
        }

        textarea, p {
            all: unset;
            border: none;
            min-height: 144px;
            max-height: none;
            height: auto;

            width: calc(100% - 48px);
            margin-right: 24px;
            background-color: #fff;
            padding: 24px;
            letter-spacing: 0.5px;
            line-height: 18px;

            color: #0A1554;
            font-size: 16px;

            overflow-wrap: break-word;
            // white-space:nowrap;
            white-space:pre-wrap;
        }

    }
}
</style>


