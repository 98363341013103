<template>
    <div class="questions-container row">
        <div class="column">
            <multiple-choice-question v-for="(question, index) in questions"
                :key="index" :question="question"/>
        </div>
        <div class="images-section column">
            <img v-for="(image, index) in imagesFromQuestions" :key="'image'+index"
                 :src="getImageUrl(image)">
        </div>
    </div>
</template>
<script>
import MultipleChoiceQuestion from '@/components/assessment/MultipleChoiceQuestion.vue'
export default {
    name: 'MultipleChoiceSection', 
    components:{
        MultipleChoiceQuestion
    }, 
    computed:{
        questions(){
            return this.$store.state.assessment.questions
                    .filter(e => e.type === 'multiple-choice')
        }, 
        imagesFromQuestions(){
            return this.questions   
                .filter(e => e.imageName)
                .map(e => {
                    return e.imageName
            })
        }
        
    }
}
</script>
<style lang="less" scoped>
.questions-container{
    .images-section{
        img{
            box-sizing: border-box;
            max-width: 498px; 
            max-height: 340px;
            height: auto;
            width: auto;
            border: 22px solid #EAEBFF;
            margin-top: 16px;
            margin-left: 8px;

            &:nth-child(1){
                margin-top: 0;
            }
        }
        
    }
    
}
</style>

