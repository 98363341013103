<template>
    <div class="assessment" v-if="assessment">
      <modal v-if="showModal" :click-handler="completeAssessment" 
        text-button="OK"  @close="showModal = false">
         <div class="message row">
           <span>{{submitMessage}}</span>
         </div>
      </modal>
      <div class="assessment-banner row">
        <img class="info-icon" :src="infoIcon" v-on:click="goToTeacherPage">
        <img :src="image" class="lesson-image">
        <div class="column">
          <div class="row">
            <span class="assessment-title"  v-tooltip="lesson.title || ''">{{$t('assessment')}}: {{lesson.title}}
            </span>
            <AudioToggle v-if="assessment && assessment.audio" :audio="assessment.audio" :white-background="true"/>
          </div>
          <div class="assessment-directions">
            <span >{{$t('assessmentDirections')}}</span>
            <p class="custom-scrollbar">{{assessment.directions}}</p>
          </div>
        </div>
      </div>

      <span v-if="assessmentSections.length == 0 && !isLoadingInformation" 
        class="centered">{{$t('noContentToDisplayMessage')}}</span>

      <template v-else>
        <div v-if="questionDirection" class="question-direction">
          <h2>{{`${ nameFilter(questionDirection.questionType) } ${locale === 'en' ? 'directions' : ''}`}}</h2>
          <p>{{questionDirection.content}}</p>
          <audio-toggle v-if="questionDirection.audio" 
                        :key="questionDirection.audio.id"
                        :audio="questionDirection.audio"/>
        </div>
        <component :is="currentSection" />
        <div class="row">
            <span class="legend" v-if="displayAssessmentScore">{{getAssessmentScore()}}</span>
        </div>
		<button class="btn round" v-if="lesson" :class="isBackButtonDisabled ? 'secondary' : 'primary'"
			:disabled="isBackButtonDisabled" @click="MoveToPreviousAssessment()">{{$t('back')}}</button>

		<button class="btn round" v-if="lesson" :class="isSubmitButtonDisabled ? 'secondary' : 'primary'"
			:disabled="isSubmitButtonDisabled" @click="MoveToNextAssessment()">{{nextButtonText}}</button>
      </template>
    </div>
</template>
<script>
import MultipleChoiceSection from '@/components/assessment/MultipleChoiceSection.vue'
import EssaySection from '@/components/assessment/EssaySection.vue'
import WrittenSection from '@/components/assessment/WrittenSection.vue'
import VennDiagramSection from '@/components/assessment/VennDiagramSection.vue'
import AudioToggle from '@/common/AudioToggle.vue'
import Modal from '@/common/Modal.vue'
import { Status } from '@/utils/Status.js'
import AssessmentScoreMixin from '@/common/AssessmentScoreMixin.js'

export default {
  name: 'Assessment',
  mixins:[AssessmentScoreMixin],
  components:{
    AudioToggle, 
    MultipleChoiceSection, 
    EssaySection,
    WrittenSection,
    VennDiagramSection,
    Modal
  },
  data(){
    return{
      showModal: false, 
      submitMessage: '',
      assessmentSections:[]
    }
  },
  methods:{
    MoveToNextAssessment(){
      let indexOfCurrentSection = this.assessmentSections
          .indexOf(this.currentSection)

      if(indexOfCurrentSection === this.assessmentSections.length -1
           && this.isPreviewMode)
        return

      if(indexOfCurrentSection === this.assessmentSections.length -1
           && !this.isAssessmentSubmitted){
        this.isAssessmentComplete()
        this.showModal = true
        return
      }
      if(indexOfCurrentSection === this.assessmentSections.length -1)
        return
      this.currentSection = this.assessmentSections[indexOfCurrentSection + 1]
    }, 
    MoveToPreviousAssessment(){
      let indexOfCurrentSection = this.assessmentSections
          .indexOf(this.currentSection)
      if(indexOfCurrentSection === 0)
        return

      this.currentSection = this.assessmentSections[indexOfCurrentSection - 1]
    },
    completeAssessment(){
      this.showModal = false
      if(!this.isAssessmentComplete())
        return 
      this.updateEssayBeforeSubmit()
      this.updateWrittenAnswersBeforeSubmit()
      this.$store.dispatch('setAssessmentCompleted', this.$route.params.lessonId)
        .then(()=>{
          this.$router.push({name: 'Unit', 
              params:{
                  ...this.$route.params}})
          this.currentSection =''
        })
        .catch(()=>{
          this.$store.commit('addSnackBarNotification',{
             message: this.$t('failedToSubmitAssessmentMessage')
          })
        })
    }, 
    isVennDiagramComplete(){
      let vennDiagram = this.assessment.questions
          .find(e => e.type === 'venn-diagram')
      if(!vennDiagram)
        return true
      
      let emptyElements = vennDiagram
              .vennDiagramElements.filter(e => e.studentAnswerSet === 'empty')
      return emptyElements.length > 0 ? false : true
    },
    isAssessmentComplete(){
      this.submitMessage = this.$t('completeAssessmentMessage')
      let multipleChoiceQuestions =this.assessment.questions
          .filter(e => e.type === 'multiple-choice')

      multipleChoiceQuestions.forEach(e =>{
          let studentAnswer = e.choices.find(e => e.correct)
          if(!studentAnswer)
            return false
      })

      let isWrittenAnswerCompleted = this.assessment.questions
                                      .filter(elem => elem.type === "written")
                                      .every(elem => typeof(elem.studentAnswer) === "string" && elem.studentAnswer != "");

      if(!isWrittenAnswerCompleted)
        return false;

      if(!this.isVennDiagramComplete()) 
        return false

      let essay = this.assessment.questions.find(e => e.type === 'essay')
      if(essay && !essay.studentAnswer)
        return false

      this.submitMessage =  this.$t('submitAssessmentMessage')
      return true
    }, 
    getAssessmentSections(){
      let sections =[]
      this.assessment.questions.map((e)=>{        
        sections.push(`${e.type}-section`)
      })
      this.assessmentSections = sections.filter(this.onlyUnique)
      if(!this.currentSection)
        this.currentSection = this.assessmentSections[0]
    },
    onlyUnique(value, index, self){ 
        return self.indexOf(value) === index;
    }, 
    updateEssayBeforeSubmit(){
      let essay = this.assessment.questions.find(e => e.type === 'essay')
      if(!essay)
        return
      this.$store.dispatch('updateEssayStudentAnswer', {
        assessmentId: this.assessment.id,
          questionId: essay.id,
          essayText: essay.studentAnswer
      })
    }, 
    updateWrittenAnswersBeforeSubmit(){
      let questions = this.assessment.questions.filter(e => e.type === 'written')
      questions.forEach(question =>{
        this.$store.dispatch('updateWrittenStudentAnswer', {
            assessmentId: this.assessment.id,
            questionId: question.id,
            writtenText: question.studentAnswer
        })
      })
    }, 
    getAssessmentScore(){
      let score = this.getScore(this.assessment.questions)
      let correctAnswers = this.getCorrectAnswers(this.assessment.questions)
      return `Score ${score}% (${correctAnswers}/${this.assessment.questions.length})`
    }, 
    loadAssessment(){
      this.isLoadingInformation = true
      this.$store.commit('loadAssessment', null)
      this.$store.dispatch('loadAssessment', this.$route.params.lessonId)
        .then(()=>{
          this.$store.commit("sortAssessmentBuilderComponents",  { 
                              targetCollectionName: "assessment",
                              getInnerCollectionFunction: obj => obj.questions,
                              setInnerCollectionFunction: (obj, collection) => { obj.questions = collection; } 
                            });
          this.getAssessmentSections();
          this.isLoadingInformation = false
          if(!this.currentSection)
            this.currentSection = this.assessmentSections[0]
        })
        .catch(()=>{
          this.isLoadingInformation = false
          this.$store.commit('addSnackBarNotification',{
            message: this.$t('failedToLoadAssessmentMessage'), 
            dismissable: true
          })
          this.$router.push({name: 'Unit', 
                params:{
                    ...this.$route.params}})
        })
    },
    nameFilter: function(value){
      let _mapper = {
        "multiple-choice": this.$t("multipleChoice"),
        "venn-diagram": this.$t("vennDiagramLongName"),
        written: this.$t("writtenAnswer"),
        essay: this.$t("essayLongName")
      };

      return _mapper[value];
	},
	goToTeacherPage(){
		if(this.$store.state.currentUnit && this.$store.state.currentLesson)
			this.$router.push({
				name: "TeacherPageLesson",
				params: {
					unitId: this.$store.state.currentUnit.id,
					lessonId: this.$store.state.currentLesson.id,
					teacherPageId: this.$store.state.currentLesson.skillsId
				},
				query: { 
					...this.$store.state.currentLesson.title 
						&& { title: this.$store.state.currentLesson.title } 
				}
			});
	}
  },
  computed:{
    assessment(){
      return this.$store.state.assessment
    },
    lesson(){
        return this.$store.state.currentLesson
    },
    image(){
      return this.lesson 
        ? this.getImageUrl(this.lesson.imageName)
        : this.getImageUrl(null)
    },
    infoIcon(){
      return require('@/assets/icons/info_outline.svg')
    }, 
    nextButtonText(){
      return this.assessmentSections.indexOf(this.currentSection) == this.assessmentSections.length - 1 ?
                this.$t('submit') :  this.$t('next');   
    }, 
    isBackButtonDisabled(){
      return this.assessmentSections.indexOf(this.currentSection) == 0;
    },
    isSubmitButtonDisabled(){
      return this.assessmentSections.indexOf(this.currentSection) == this.assessmentSections.length - 1
                && this.isAssessmentSubmitted;
    },
    currentSection:{
      get(){
        return this.$store.state.currentAssessmentSection
      },
      set(value){
        this.$store.commit('updateCurrentAssessmentSection', value)
      }

    },
    isPreviewMode(){
        return this.$store.state.isPreviewModeActive
    }, 
    isAssessmentSubmitted(){
        return this.assessment.assessmentCompleted
    },
    displayAssessmentScore(){
      if(this.isPreviewMode)
        return false

      return this.lesson.status === Status.Failed 
          || this.lesson.status === Status.Completed
    },
    questionDirection(){
      if(!this.assessment.questionDirections)
        return ''
      return this.assessment.questionDirections
                            .find(item => `${item.questionType}-section` === this.currentSection);
    }
  }, 
  created(){
    this.loadAssessment()
  },
  watch:{
    '$route': function(val){
        if(val.query.refresh){
          this.loadAssessment()
          this.$router.push({
            name: this.$route.name,
            params: this.$route.params
          })
        }
    }
  },
    mounted(){
        this.startTrackingPage({ sectionName: "Assessment" });
        this.$store.commit("setAnalyzedManagementSection", "Assessment");
    },
    beforeDestroy(){
        this.$store.commit("setAnalyzedManagementSection", "");
        this.stopTrackingPage({ sectionName: "Assessment" });
    }
}
</script>

<style lang="less" scoped>
.assessment{
  width: 1280px;
  margin: 0 auto;
  margin-top: 104px;
  height: auto;
  overflow-x: hidden;  
  .message{
    height: 143px;
    width: 390px;
    text-align: center;
    span{
      margin: auto;
      white-space: normal;
    }
  }
  .questions-container{
    margin-left:40px;
    margin-top:52px;
  }
  .btn{
    margin-top: 16px;
    margin-left: 39px;
	height: 40px;
	
	&:focus{
		outline: none;
	}
  }
  .btn:last-child{
    float: right;
    margin-right: 39px;
    margin-bottom: 31px;
  }
  >.row{
    margin-top: 16px;
    .legend{
      margin-left: auto;
    }
  }
  .assessment-banner{
    width: 1280px;
    height: 250px;
    max-width: 1280px;
    max-height: 250px;
    background-color: #0A1554;
    padding: 16px;
  
    .info-icon{
      margin-left: 24px;
      margin-top: 16px;
      margin-bottom: auto;
      cursor: pointer;
    }
  
    .lesson-image{
      margin-left: 60px;
      max-height: 244px;
      max-width: 468px;
    }
    .audio-toggle{
      margin-top: 16px;
    }
    .column{
      margin-left: 40px;
    }
    .assessment-title{
        margin-top: 8px;
        color: #fff;
        min-height: 32px;
        width: 582px;
        font-family: 'Montserrat', sans-serif;
        font-size: 24px;
        font-weight: bold;
        letter-spacing: 0.25px;
        text-align: left;
        margin-left: 0;
        text-overflow:initial;
        white-space: normal;
        &::first-letter{
          text-transform: capitalize;
        }
    }
    .assessment-directions{
      margin: auto;
      box-sizing: border-box; 
      min-height: auto;
      width: 624px;
      background-color: #EAEBFF;
      padding: 12px 16px;
      font-family: 'Roboto', sans-serif;
      font-style: italic;
      font-size: 18px;
      letter-spacing: 0.4px;
      line-height: 24px;
      max-height: 190px;
      span {
        height: 16px;
        width: 70px;
        color: #0A1554;
        text-transform: capitalize;
      }
      p{
        height: auto;
        width: 600px;
        color: #3A4DFD;
        margin-top: 4px;
        overflow-y: auto;
        max-height: 150px;
      }
    }
  
  }
  .essay-container{
    margin: 29px 40px 0 40px;
    overflow-x:hidden;
  }

  .question-direction{
    position: relative;

      width: 1200px;
      margin: 0px auto;
  
      p{
        font-family: 'Roboto', sans-serif;
        font-style: italic;
        font-size: 18px;
        letter-spacing: 0.4px;
        line-height: 22px;
    }
    
    .audio-toggle{
      position: absolute;
      top: 44px;
      left: -30px;
    }
  }
}

</style>