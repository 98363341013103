export default{
    props:{
        question: Object,
    }, 
    data: function(){
        return{autoSaveInterval: null}
    },
    computed:{
        studentAnswer:{
            set(value){
                if(this.isPreviewMode)
                    return
                if(this.isAssessmentSubmitted){
                    this.$store.commit('addSnackBarNotification',{
                        message: this.$t('submittedAssessmentAlert')
                    })
                    return
                }
                this.$store.commit('updateStudentAnswer', {
                    question: this.question,
                    answer:value
                })
            },
            get(){
                return this.question.studentAnswer
            }
        },
        assessment(){
            return this.$store.state.assessment
        }, 
        isPreviewMode(){
            return this.$store.state.isPreviewModeActive
        }, 
        isAssessmentSubmitted(){
            return this.assessment.assessmentCompleted
        }, 
    },
    methods: {
        ToggleAutoSaveInterval(){
            if(this.assessmentSubmitted){
                this.$store.commit('addSnackBarNotification',{
                    message: this.$t('submittedAssessmentAlert')
                })
                return
            }
            clearInterval(this.autoSaveInterval)
            this.autoSaveInterval =setInterval(function () {
                this.updateStudentAnswer()
            }.bind(this), 15000); 
        }, 
        clearAutoSaveInterval(){
            this.updateStudentAnswer()
            clearInterval(this.autoSaveInterval)
        }
    },
    beforeDestroy(){
        if(this.isPreviewMode)
            return
        clearInterval(this.autoSaveInterval)
        if(this.question && this.assessment)
            this.updateStudentAnswer()
    }
}