<template>
    <div class="essay-container" >
        <EssayTextArea :question="essay"/>
        <AnswerFeedBack v-if="feedback" :question="essay"/>
    </div>
</template>
<script>
import EssayTextArea from '@/components/assessment/EssayTextArea.vue'
import AnswerFeedBack from '@/components/assessment/AnswerFeedBack.vue'
export default {
    name: 'EssaySection',
    components:{
        EssayTextArea, 
        AnswerFeedBack
    },
    computed:{
        feedback(){
            return this.essay.feedback
        }, 
        essay(){
            return this.assessment.questions.find(e => e.type === 'essay')
        }, 
        assessment(){
            return this.$store.state.assessment
        }, 
    } 
}
</script>

