<template>
    <div class="questions-container column">
        <template v-for="question in questions" >
            <written-question :key="question.id" :question="question"/>
            <AnswerFeedBack v-if="question.feedback" :key="'feedback'+question.id" :question="question"/>
        </template>
    </div>
</template>

<script>
import WrittenQuestion from "./WrittenQuestion.vue"
import AnswerFeedBack from '@/components/assessment/AnswerFeedBack.vue'
export default {
    name: "WrittenSection",
    components: {
        WrittenQuestion, 
        AnswerFeedBack
    },
    computed: {
        questions(){
            return this.$store.state.assessment.questions.filter(elem => elem.type === "written");
        }
    }
}
</script>

<style lang="less" scoped>
    .questions-container{
        > div{
            margin-top: 16px;
        }

        .written-question:first-child{
            margin-top: 0;
        }
    }
</style>
